/* .modal.fade .modal-dialog{
    overflow-y:scroll;
} */

/* label::after {
    content: '*';
    color: red;
  } */

  .dashboarddatatable{
    max-height:max-content!important;
    overflow:visible!important;   
  }
   .table-responsive{
    overflow: inherit !important;
  } 
  /* .table-responsive header{
    display: none;
  } */
  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .table-responsive{
      overflow: auto !important;
    } 
    .hide-on-mob {
      display: none;
    }
    .setting-but {
      position: absolute;
      right: 0;
      top: -90px;
    }
    .table-responsive input.w-25 {
      width: 40% !important;
    }
    
  .dashboarddatatable{
    max-height:max-content!important;
    overflow:auto!important;   
  }
  }
  
.smallfont{
  font-size: 12px!important;
  font-weight: normal;
  margin-bottom: 0px;
  line-height: 18px;
}
.prquanity, .prprice, .prttotalprice, #thquantity, #thprice, #thtotal {
  width: 80px;
}
#thdiscountedamount{width: 100px;}
.prdate, #thdate {
  width: 100px;
}
.prunit, #thunit {
  width: 65px;
}
.prvat, #thvat {
  width: 65px;
}
#thtotalvat, #thtotalexclvat {
  width: 110px;
}
#thdiscount {
  width: 110px;
}
.addingrows tr td{
  padding: 8px 5px;
}
.total-sec .form-group {
  float: right;
  max-width: 150px;
  margin-bottom: 10px;
}

/* //////////////////////////// for invoice preview ////////////////////////// */
#previewinvoice {
  height: 100%;
}
.previewheader {
  height: 55px;
  background: #9D3AD2;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999;
  text-align: right;
  padding: 5px;
  
}
  .previewheader .btn {
      border-radius: 7px;
      padding: 12px 23px;
      width: auto;
  }

.comp-address {
  width: 100%;
  border-bottom: 2px dashed #4c4c4c;
  padding: 1px;
  background: #f4f4f4;
  /* border-radius: 50px; */
  border-top: 2px solid #cccccc;
}
.producttable table tr td, .producttable table tr th {
  font-size: 12px !important;
}



.prevfooter p {
  margin-bottom: 0px !important;
  line-height: 18px !important;
}

.prevfooter .comp-nm {
  line-height: 18px !important;
  margin-bottom: 0px !important;
}
.dropzone {
  margin-right: auto;
  margin-left: auto;
  padding: 25px;
  text-align: center;
  border: 2px dashed var(--theme-default);
  border-radius: 10px;
  -webkit-border-image: none;
  -o-border-image: none;
  border-image: none;
  background: rgba(83, 70, 134, 0.1);
  box-sizing: border-box;
  min-height: 150px;
  position: relative;
}
.dropzone * {
  box-sizing: border-box;
}
.dropzone i {
  font-size: 35px;
  margin-bottom: 10px;
  color: var(--theme-default);
}

.prevfooter tr td {
  border: 2px solid #000000 !important;
}

  .prevfooter tr td.nobordertop {
      border-top: none !important;
  }

  .prevfooter tr td.noborderleft {
      border-left: none !important;
  }

  .prevfooter tr td.noborderright {
      border-right: none !important;
  }

  .prevfooter tr td.noborderbottom {
      border-bottom: none !important;
  }

img.footericon {
  width: 15px !important;
}

.cutters {
  position: absolute;
  bottom: -14px;
}


.small-p {
  font-size: 12px;
  font-weight: bold;
}
#invoicepreview{
  font-size: 10px;
}
#invoicepreview table.dataTable thead th, #invoicepreview table.dataTable thead td{
  padding: 10px 2px;
}
#totalvatshow p{
  margin-bottom:3px;
}
#tableinvoiceproduct tr td,#tableinvoiceproduct tr th{
  padding:8px 2px;   
}
 #tableinvoiceproduct.table > :not(caption) > * > *{
  padding:0.5rem 3px;
} 
.invoicestripe{
  overflow-x: inherit!important;
  overflow-y: inherit !important;
  max-height: initial!important;
}
.add-products1, #modalAddProduct, .topmodal {
  z-index: 1052;
}
body .modal-backdrop:nth-child(2nd) {
  z-index: 1051!important;
}


.text-invoice {	
    background-image: url(../public/assets/images/txt-img.png);
	background-repeat: no-repeat;
	width: 100%;
	height: auto;
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
	background-size: auto;
	background-position: bottom center;
}
.invoice-content-wrap {
	background:white;
	position: relative;
	height: 100%;
	width: 100%;
}
.invoice-container {
	max-width: 880px;
	margin: 0 auto;
	padding: 30px 15px;
}
.wid-20{
	width: 20%;
}
.wid-40{
	text-align: start;
	width: 102px;
}
.wid-50{
	width: 50%;
}
.logo img {
	display: block;
	height: 100%;
	max-height: 40px;
}

.invoice-logo-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.invoice-logo-hotel {
	background-image: url(../public/assets/images/blue-bg.png);
	background-repeat: no-repeat;
	width: 40%;
	height: auto;
	background-size: cover;
	padding: 30px 50px;
}
.pink-bg{
	background-image: url(../public/assets/images/pink-bg.png);
	background-repeat: no-repeat;
	width: 100%;
	height: auto;
	background-size: auto;
	background-position: right;
	padding: 15px 0;
	background-size: contain;
}
.invoice-logo-hotel-left{
	background-image: url(../public/assets/images/black-bg.png);
	background-repeat: no-repeat;
	width: 60%;
	height: auto;
	background-size: cover;
	padding: 21px 50px;
}
.invo-head-content.invoice-logo-hotel-left {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
img.txt-img {
	position: absolute; 
	display: flex;
	justify-content: center;
	align-items: center;
}
.invo-head-wrap {
    display: flex;
    justify-content: end;
    padding-bottom: 10px;
}
.invo-head-content.invoice-logo-hotel-left .invo-head-wrap,.invo-head-content.invoice-logo-hotel-left .invo-head-wrap.invoi-date-wrap {
	width: 100%;
}
.booking-content-wrap {
	display: flex;
	justify-content: space-between;
	column-gap: 4px;
}
.detail-col {
	width: 100%;
	background: #F5F5F5;
	padding: 9px 10px;
}
.tax-row.bottom-border, .disc-row.bottom-border {
	border-bottom: 2px solid #12151C;
}
.payment-table-wrap {
	padding: 10px 20px;
	background: #F5F5F5;
	margin-top: 10px;
	position: relative;
	z-index: 10;
}
table.invo-payment-table.invo-payment-table {
	width: 100%;
}
.invo-payment-table {
	border-collapse: collapse;
}
.invo-paye-row {
	border-top: 2px solid #12151C;
}
.payment-desc.payment-desc {
	padding: 20px 0 0;
}
.hotel-table thead tr th{
	padding-bottom: 10px;
}
.hotel-table thead tr th:first-child{
	text-align: left;
}
.hotel-table thead tr th:nth-child(2){
	text-align: left;
}

.rest-payment-bill {
	display: flex;
}
.payment-wrap {
	border: 2px solid #12151C;
	padding: 0px 20px 0px 20px;
	display: inline-block;
}
.signature-wrap {
	text-align: center;
	align-items: center;
	position: relative;
	left: 19%;
	padding-top: 50px;
}
.bus-invo-no-date-wrap {
	padding: 20px 0px;
	display: flex;
	justify-content: space-between;
}
.res-contact{
	padding-top: 30px;
}
.res-bottom{
	padding-bottom: 30px;
}
.restaurant-header{
	padding: 0 50px;
}
.restaurant-table thead th{
	padding: 10px 0;
}
.restaurant-table tbody td:nth-child(odd){
	background: #F5F5F5;
	text-align: center;
}
.restaurant-table tbody td:nth-child(2){
	padding-left: 30px;
}
.restaurant-table thead th:nth-child(2){
	padding-left: 30px;
	text-align: left;
}
.restaurant-table thead tr th:first-child{
	text-align: left;
}
.disc-row td {
	padding-bottom: 20px;
}
.res-pay-table-res{
	border-collapse: collapse;
	width: 100%;
}
.res-pay-table-res .pay-type{
	padding: 20px;
}
.p-0{
	padding: 0px !important;
}
.restaurant .rest-payment-bill{
	align-items: center;
}
.restaurant .payment-wrap{
	border: 2px solid #F5F5F5;
}
.restaurant .invoice-logo-content{
	align-items: center;
}
.res-no{
	width: 10%;
}
.res-pric{
	width: 12%;
}
.res-qty{
	width: 20%;
}
.res-total{
	width: 16%;
}
.restaurant .res-pay-table .pay-type{
	padding: 20px;
}
.invoice-logo-res{
	display: flex;
	align-items: center;
	justify-content: end;
}
.invo-tb-body {
    border-bottom: 2px solid #12151C;
    border-top: 2px solid #12151C;
}
.invo-tb-body .invo-tb-row {
    border-bottom: 1px solid #505050;
}
.table-bg {
    background: #F5F5F5;
}
.hotel-table td {
    padding: 10px 0;
}
.invo-addition-wrap {
    display: flex;
    justify-content: space-between;
}
.invo-bill-total {
    width: 50%;
    position: relative;
}
.hotel-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    white-space: nowrap;
}
.invoice-owner-conte-wrap {
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
}

/* //////////////////////////////////// preview /////////////////////// */

.m-350 {
  min-height: 350px;
}
.prevfooter tr td, .prevfooter tr th {
  border-collapse: collapse;
  padding: 5px;
  vertical-align: top;
}
.prevfooter tr td {
  border: 2px solid #000000 !important;
  font-size: 11px;
}
.prevfooter tr td p {
  font-size: 11px;
  margin-bottom: 0;
}
.prevfooter tr td.noborderleft {
  border-left: none !important;
}
.prevfooter tr td.nobordertop {
  border-top: none !important;
}
.prevfooter tr td.noborderright {
  border-right: none !important;
}
.prevfooter tr td.noborderbottom {
  border-bottom: none !important;
}
.b-b-t {
  border-bottom: 2px dashed #4C4C4C;
}
.cutters {
  position: absolute;
  bottom: -10px;
  width: 30px;
}
.prv-head .invoice-logo-hotel {
  padding: 15px 30px;
}
.previewheader {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999;
  text-align: right;
  padding: 5px;
}
.prevfooter tr td strong {
  font-weight: bold;
}
.prevfooter tr td img {
  max-width: 150px;
}
.successl thead .invo-tb-header th {
  background: #CCCCCC;
  color: #000000;
  font-size: 12px;
  padding: 6px 6px;
}
.successl tbody.invo-tb-body {
  border-bottom: 1px solid #B6B6B6;
  border-top: 1px solid #12151C;
}
.successl tbody .invo-tb-row td, .successl tfoot tr td, .successl tbody.invo-tb-body1 tr td {
  padding: 7px 0;
  font-size: 12px;
}
/* ////////////////////////////// ticket files ///////////////////////////////// */
.fileuploaded {
  position: relative;
  display: inline-block;
      margin: 15px 5px;
}
      .removefileticketfile {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  color: red;
  background: #fff;
  padding: 4px;
}
      .fileuploaded:hover .removefileticketfile{display:inline-block;}

      #sentinvoicewallet {
        height: 350px;
        overflow-x: auto;
        margin-top: 5px;
    }

    .payment-details {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 15px 20px;
  }

  #accordionupcoming .card .card-header{
    padding:0px;
  }

  .accordion.accordion-filled .card .card-header a{
    transition: all 0.2s linear;
    color: #fff;
    font-weight: 700;
    padding: 1.2rem 4.5rem 1.2rem 1.2rem;
    display: block;
    font-size: 1rem;
  }
  .eve-h {
    text-align: center;
    margin-bottom: 15px;
    background: rgb(255, 255, 255, 0.8);
    color: #000000;
    padding: 10px 0;
}
.event-li p {
  background: rgb(0, 0, 0, 0.2);
  padding: 5px 10px;
}

#uploadfileschat{
  opacity: 0.0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -moz-opacity: 0.0;
    -khtml-opacity: 0.0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
#chat-side1{
  z-index: 9;
}

/* ////////////////////////////////// For offer letter //////////////////////// */
#example-form5 .wizard > .steps {
  display: block;
}
.h3_ght {
  font-size: 17px;
  font-weight: bold;
  margin: 8px 0 35px
}
.inr-cat {
  color: #000000 !important;
  text-align: center;
  padding-left: 18px !important;
  min-width: 40px
}
.size-a4 {
/* height: 297mm;*/
padding: 0;
width: 210mm;
}
.pdf-page {
background-color: #fff;
box-sizing: border-box;
color: #333;
margin: 0 auto;
position: relative;
margin-bottom:10px;
}
.invoice_style1 .body-shape1 {
bottom: 0;
left: 0;
top:0;
position: absolute;
width: 100%;
height:190px;
}
.footericon {
width: 15px;
}
.th-invoice {
background-color: #fff;
position: relative;
z-index: 4;
}
.invoice-container-wrap {
height: 100%;
}
#head1footer, #head2footer {
bottom: 0;
position: absolute;
}
#head1footer {
width: 87%;
}
.invoice_style1 .th-header {
margin-top: 10px;
padding-right: 30px;
}
.th-invoice .download-inner {
padding: 50px;
}
.th-invoice .download-inner p {
  margin-bottom: 2px;
  }
.footernoborder tr td {
font-size: 10px;
padding: 2px 5px!important;
}
.invoice_style1 {
border-bottom: 15px solid #E90031;
height: 100%;
padding-bottom: 0;
}
.invoice-container {
height: 100%;
margin: 0 auto;
padding: 0;
position: relative;
width: 100%;
z-index: 5;
padding-bottom: 75px;
}
.mydiv1 {
display: flex;
}
.md1{width:50%;}
.basictable tr td{padding:5px;}
.editsection{display:block; position:relative;}
.editlink, .savelink{position:absolute; top:-10px; right:-10px;z-index:9;}
.editlinkjoining, .savelinkjoining{position:absolute; top:-10px; right:-10px;z-index:9;}
.deleteContact {
border: 0;
background: none;
color: #A53CCD;
font-size: 20px;
}
.loader {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffffd6;
  z-index: 99;
  text-align: center;
  display: none;
  padding-top: 10%;
}
.deletesection{
  position:relative;
}
.deletlink{
  position:absolute;
  left:-5px;
  top:2px;
}
.blockheading{
  display:inline-block!important;
  font-weight:bold;
}
.currentdate{
  position:absolute;
  right:50px;
}
.invoice_style9 {
  background: url(../public/assets/images/letterheadbg2.jpg) no-repeat top, url(../public/assets/images/letterheadbg2_1.jpg) repeat-y;
  height: 100%;
}
.invoice_style9 .footer-info {
align-items: center;
background-image: linear-gradient(180deg,#15717B,#10565E);
border-radius: 100px 100px 0 0;
bottom: 0;
display: flex;
justify-content: space-between;
left: 0px;
padding: 15px 50px;
position: absolute;
width: calc(100% - 100px);
z-index: -2;
}
.invoice_style9 .footer-info p {
color: #fff;
font-size:11px;
}
#head2footer{width:100%;}
.download-inner .table tr{
  background:none!important;
}
.invoice_style4 {
padding-bottom: 60px;
height: 100%;
}
.th-invoice {
position: relative;
z-index: 4;
background-color: #FFFFFF;
}
.th-invoice .download-inner {
padding: 50px;
}
.invoice_style4 .th-header {
margin-top: -26px;
margin-bottom: 100px;
}
.invoice_style4 .body-shape1 {
  top: 0;
  position: absolute;
  z-index: -1;
  left:0px;
}
.invoice_style4 #head2footer {
position: absolute;
width: 100%;
bottom: 0px;
left: 0px;
font-size: 10px;
}
.invoice_style4 .footer-info {
position: absolute;
bottom: 0;
left: 0;
width: 100%;
background-color:#e90031;
display: flex;
align-items: center;
justify-content: space-between;
padding: 20px 50px 20px 80px;
border-radius: 35px 0 0 0;
z-index: -2;
}
.invoice_style4 .footer-info:before {
content: '';
position: absolute;
inset: 0;
left: 30px;
background-color: #242437;
z-index: -1;
border-radius: inherit;
}
.invoice_style4 .footer-info p {
color:#ffffff;
font-size:10px;
}
/* //////////style End//////// */

/* /////////////////////below letterhead css//////////////// */ 
.carousel-control-next-icon, .carousel-control-prev-icon {
  background-color: #9D3AD2;
}
.carousel-caption {
  color: #000000;
}
.carousel-caption {
  bottom: 20%;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
        .edittemplate {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9;
            background: #FF0000;
            color: #fff;
            padding: 5px 10px;
            font-weight: bold;
            font-size: 20px;
        }
        .card__number{
            font-size: 17px;
            padding: 16px 0 19px;
        }
        .activetemplate {
            position: absolute;
            top: 0;
            left: 0;
            background: #00A305;
            color: #FFFFFF;
            padding: 6px 15px;
            font-size: 25px;
        }
        .inactivetemplate {
            position: absolute;
            top: 0;
            left: 0;
            background: #FF0000;
            color: #FFFFFF;
            padding: 6px 15px;
            font-size: 25px;
        }
        .submittemplate {
            align-items: center;
            background-color:#9d3ad2;
            border: none;
            color: #FFFFFF;
            font-weight: 700;
            padding: 11px 35px;
                border-radius: 99px;
        }

        .body-shape1 {
          position: absolute;
          top: 0;
          left: 0;
      }
      .clspaidoffline {
        position: absolute;
        bottom: 0;
        font-size: 10px;
    }

    .input-group > .form-control:focus, .input-group > .form-select:focus {
      z-index: auto;
  }

  
.logincircle img{width: 100%;}

.logincircle {
  background: #ebebf1;
  border: 1px solid #838286;
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  line-height: 29px;
  margin: 0 10px;
  overflow: hidden;
  padding: 9px;
  width: 50px;
}
.step-btn {
  min-width: 180px;
  border-radius: 0;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.step-navigation .btn {
  border-radius: 0;
  margin-right: 10px;
  min-width: 150px;
}


#head2footer .footer-info {
  align-items: center;

  background-color:#010F1C;
  border-radius: 35px 0 0 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 20px 50px 20px 80px;
  position: absolute;
  width: 100%;
  z-index: -2;
  color: #ffffff;
}


/*  ////////////////////////////////////////// chat option /////////////////////////////// */

button.chatbox-open {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 45px;
  height: 45px;
  color: #fff;
  background-color: #11555E;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  margin: 5px;
  font-size: 11px;
  transition: 1s;
}
button.chatbox-close {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 45px;
  height: 45px;
  color: #fff;
  background-color: #11555E;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  font-size: 11px;
  margin: 10px calc(2 * 5px + 45px) 5px 5px;
  transition: 1s;
}
button.chatbox-close:hover, button.chatbox-open:hover {
  color:#ffffff;
  transform: scale(0.7);
}
.chatbox-popup {
  display: flex;
  position: fixed;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
  display: none;
  bottom: calc(2 * 10px + 45px);
  right: 16px;
  width: 377px;
  height: auto;
  background-color: #fff;
  border-radius: 16px;
  z-index: 10;
}
.chatbox-popup .chatbox-popup__header {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 10px;
  color: #fff;
  background-color: #11555E;
  align-items: center;
  justify-content: space-around;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.chatbox-popup .chatbox-popup__header .chatbox-popup__avatar {
  margin-top: 0px;
  background-color: #11555E;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.chatbox-popup .chatbox-popup__main {
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * 5px) 16px 0;
  line-height: calc(16px + 16px / 2);
}
.chatbox-popup__main.chat .chat-msg-box {
  max-height: 270px;
  margin-bottom: 5px;
}
.chatbox-popup .chatbox-popup__footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: space-around;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.chatbox-panel {
  display: flex;
  position: fixed;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  width: 377px;
  background-color: #fff;
  z-index: 10;
}
.chatbox-panel .chatbox-panel__header {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 16px;
  color: #fff;
  background-color: #11555E;
  align-items: center;
  justify-content: space-around;
  flex: 0 0 auto;
  z-index: 10;
}
.chatbox-panel .chatbox-panel__main {
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * 10px) 16px 0;
  line-height: calc(16px + 16px / 2);
  flex: 1 1 auto;
}
.chatbox-panel__main.chat .chat-msg-box {
  max-height: 520px;
  margin-bottom: 0;
}
.chatbox-panel .chatbox-panel__footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: space-around;
  flex: 0 0 auto;
}

.chat .chat-msg-box {
  margin-top: 25px;
  padding: 0px;
  overflow-y: auto; 
  height: 530px;
  margin-bottom: 30px;
}
.chat .chat-msg-box .chat-user-img {
  margin-top: -25px;
}
.chat .chat-msg-box .message-data {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.chat .chat-msg-box .message-data-time, .chat .chat-msg-box .message-name {
  letter-spacing: 1px;
  font-size: 12px;
  color: #59667a;
  font-weight: 600;
}
.chat .chat-msg-box .message {
  margin-top: 20px;
  color: #000000;
  padding: 10px;
  line-height: 1.7;
  letter-spacing: 1px;
  font-size: 13px;
  margin-bottom: 15px;
  width: 90%;
  position: relative;
}
.chat .chat-msg-box .my-message {
  border: 1px solid rgba(83, 70, 134, 0.1019607843);
  border-radius: 10px;
  border-radius: 0 10px 10px 10px;
  background-color: #f4f4f4;
}
.chat .chat-msg-box .other-message {
  border: 1px solid rgba(83, 70, 134, 0.1019607843);
  border-radius: 10px 0 10px 10px;
  background-color: #ffd8c7;
}
.chat .chat-message {
  padding: 20px;
  border-top: 1px solid rgba(83, 70, 134, 0.1019607843);
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  bottom: 0;
}
.chat .chat-message .smiley-box {
  background: #efefef;
  padding: 10px;
  display: block;
  border-radius: 4px;
  margin-right: 0.5rem;
}
.chat .chat-message .text-box {
  position: relative;
}
.chat .chat-message .text-box .input-txt-bx {
  height: 50px;
  border: 2px solid #11555e;
  padding-left: 18px;
  font-size: 12px;
  letter-spacing: 1px;
}
.chat .chat-message .text-box i {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: rgba(83, 70, 134, 0.1019607843);
  cursor: pointer;
}
.chat .chat-message .text-box .btn {
  font-size: 16px;
  font-weight: 500;
}
.chat-box .chat-menu {
  right: 10px;
  opacity: 0;
  transform: translateY(-30px);
  visibility: hidden;
  top: 95px;
  position: absolute;
  z-index: 3;
  background-color: #EFF3F9;
  border: 1px solid rgba(83, 70, 134, 0.1019607843);
  transition: all linear 0.3s;
  padding-right: unset;
}
.chat-box .chat-menu .tab-pane {
  padding: 0 15px;
}

.chatprobbtn{
  padding:8px 10px;
  border: 1px solid #9f9d9d;
  border-radius: 5px;
  background: #cccccc;
  display: inline-block;
  margin: 3px;
}
[data-simplebar]{
  align-content:normal !important;
}

/* /////       //////////////////////////////////// ticket ////////////////////////////// */
.customerreplay {
  box-shadow: 2px 2px 8px #c2c2c2;
  padding: 10px;
}

.ddetails {
  max-height: 30px;
}

.rdt_TableCell div:first-child{
  white-space: break-spaces!important;
}

.fc-scrollgrid-sync-table a{
  overflow: hidden;
}

.react-datepicker-popper{
  z-index: 99!important;
}
.react-datepicker-wrapper{
  float: left;
}
.logosize{
  max-width: 100px;
}

.invoice_style1 .body-shape4 {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.logo-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -3;
}

 .right-shape {
  position: absolute;
  right: 0;
  top: 93px;
  z-index: -1;
}

.left-shape {
  left: 0;
  position: absolute;
  top: 93px;
  z-index: -1;
}
.header-layout1 .header-logo img {
  max-height: 50px;
  max-width: 150px;
}
.header-layout1 .header-logo {
  margin-top: -23px;
}

.header-layout1 .invoice-date, .header-layout1 .invoice-number {
  color: #fff;
  font-size: 13px;
}
.header-layout1 .big-title {
  color: #fff;
  font-size: 20px;
  margin-top: 35px;
}
.header-layout1 .invoice-number {
  margin-bottom: 5px;
  margin-top: 30px;
}

.closepopupterms{
  position: absolute;
top: -10px;
right: -10px;
background: #ff0000!important;
color: #ffffff;
width: 50px;
height: 50px;
border-radius: 50%;
font-weight: bold;
    font-size: 35px;
    line-height: 1;
    z-index: 99;
}
.tabterms .nav-link{
  font-weight: bold;
  font-size: 15px;
  background: #f1f1f1;
}
.tabterms .nav-link.active{
  background: #90dbea;
}
.termconditionpopup .modal-fullscreen {
  height:auto;
  }
  
  .pulse-css {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    border-radius: 3.5rem;
    height: .4rem;
    position: absolute;
    background: #2f4cdd;
    right: 5px;
    top: .6rem;
    width: .4rem;
}
.pulse-css:after, .pulse-css:before {
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -.2rem;
  background-color: #2f4cdd;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}
@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; } }
@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; } }


    .clslanguagechnge{
      width: 100px;
    }
    .lngselecter{
      width: auto!important;
    }
    .maxwidth100{
      max-width: 100%!important;
    }
    .clsphonecodeother{
      max-width: 100px;
    }
